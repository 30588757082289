import { Grid } from '@mui/material';

import useSubProperty from './sub-property-hooks';
import { HeaderLayout, Layout } from '../InRoomDining';
import { FullScreenLoader, ServiceCard } from '../../components';
import { TalkToUS } from '../../components/TalkToUs';
import { customTheme } from '../../constants';
import { ThemeProvider } from '@material-ui/styles';
const SubProperties = () => {
  const { subProperties, loading, hotelServID } = useSubProperty();
  if (loading) {
    return <FullScreenLoader />;
  }

  return (
    <Layout>
      <HeaderLayout title="Property">
        <TalkToUS sx={{ alignItems: "center" }}></TalkToUS>
      </HeaderLayout>
      <ThemeProvider theme={customTheme}>
        <Grid container spacing={2}
          sx={{
            px: 2,
            pt: 0,
            flex: 1,
            overflow: 'scroll',
            mt: "0px",
            // background: '#F4F4F4',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          {Array.isArray(subProperties) &&
            subProperties
              ?.filter(sp => sp)
              ?.map((sp: any) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <ServiceCard
                      showInfo={false}
                      key={sp?.name}
                      label={sp?.name}
                      path={`../service-information/${hotelServID}?SPID=${sp?.id}`}
                      description={sp?.desc}
                      image={sp?.img as string}
                    />
                  </Grid>
                );
              })}
        </Grid>
      </ThemeProvider>

    </Layout>
  );
};

export default SubProperties;
