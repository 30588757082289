import * as React from 'react';
// import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress} from '@mui/material';

import {
  usePropertyDetailsQuery,
  useHotelDetailsLazyQuery
} from '../../../generated/graphql';
import { CardPaper } from './CardPaper';
import { imageFallback, useDomain } from '../../../utils';
// import { useAuth } from '../../../store';
import { Header } from './Header';

const useInfo = () => {
  const [hotelDetailsQuery, { data: propertyMeta, loading: loadingMeta }] =
    useHotelDetailsLazyQuery();

  const domainId = useDomain();
  const { data: propertyDetails, loading: loadingProperty } =
    usePropertyDetailsQuery({
      variables: {
        domain: domainId as string
      }
    });

  React.useEffect(() => {
    const propertyID = propertyDetails?.getPropertyByDomain?.id;
    if (!propertyID) return;

    hotelDetailsQuery({
      variables: {
        propertyID
      }
    });
  }, [propertyDetails?.getPropertyByDomain?.id, hotelDetailsQuery]);

  const descriptionString = propertyMeta?.getMeta?.about?.desc;
  const imgUrl = propertyDetails?.getPropertyByDomain?.img;
  const currency = propertyDetails?.getPropertyByDomain?.currency;
  const layout = propertyDetails?.getPropertyByDomain?.layout;
  const background_color =
    propertyDetails?.getPropertyByDomain?.background_color;
  const button_bg_color = propertyDetails?.getPropertyByDomain?.button_bg;
  const titleColor = propertyDetails?.getPropertyByDomain?.title_color;
  const bgImage = propertyDetails?.getPropertyByDomain?.background_image;
  const bgOpacity = propertyDetails?.getPropertyByDomain?.transperancy;
  const logoTileBg = propertyDetails?.getPropertyByDomain?.logo_tile_bg;
  const logoTileOpacity =
    propertyDetails?.getPropertyByDomain?.logo_tile_transperancy;

  return {
    background_color,
    currency,
    imgUrl,
    descriptionString,
    loading: loadingMeta || loadingProperty,
    layout,
    button_bg_color,
    titleColor,
    bgImage,
    bgOpacity,
    logoTileBg,
    logoTileOpacity
  };
};
export default useInfo;

const InfoCard = () => {
  // const navigate = useNavigate();
  const { imgUrl, loading, logoTileBg, logoTileOpacity } =
    useInfo();
  // const guest = useAuth((state: any) => state.guest);
  // const [showMore, setShowMore] = React.useState(false);
  // const toggleShowMore = () => {
  //   setShowMore(!showMore);
  // };
  // const getDescription = () => {
  //   const words = descriptionString?.split(' ');
  //   return `${words?.slice(0, 20).join(' ')}...`;
  //   if (showMore) {
  //     return descriptionString;
  //   } else {
  //     const words = descriptionString?.split(" ");
  //     const wordLength = words?.length || 0;
  //     if (wordLength > 20) {
  //       return `${words?.slice(0, 20).join(" ")}...`;
  //     } else {
  //       return descriptionString;
  //     }
  //   }
  // };

  if (loading) {
    return (
      <Box sx={{ my: 4, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  const backgroundStyles = logoTileBg
    ? {
      background: `linear-gradient(rgba(43, 43, 43, ${1.0 - (logoTileOpacity || 0.3)}), rgba(0, 0, 0, 0)), url(${logoTileBg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }
    : {};
  const barStyles = {
    backgroundPosition: 'center',
    boxSizing: 'border-box',
    background: 'linear-gradient(rgb(104 104 104 / 90%), rgb(150 150 150 / 80%))',
    borderImageSlice: 1,
    height: "80px"
  }

  return (
    <CardPaper
      sx={{
        minHeight: '259px',
        height: "259px",
        borderRadius: '0 0 40px 40px',
        boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.3)',
        ...backgroundStyles

      }}
    >
      <Header />
      <Box
        sx={{
          objectFit: 'contain',
          maxWidth: '90%',
          width: {
            xs: "80%",
            sm: "'50%'"
          },
          position: 'relative',
          '@media(max-width:280px)': {
            top: "40px",
          },
          top: '55px',
          left: '50%',
          height: "30%",
          transform: 'translate(-50%, -50%)',
          zIndex: 1,
          filter: "contrast(200%) brightness(1.5)"
        }}
        src={imgUrl}
        alt="App Logo"
        component="img"
        onError={imageFallback}
      />
      <Box
        sx={{
          ...barStyles,
          position: 'relative',
          '@media(max-width:280px)': {
            top: "-55px",
          },
          top: "-40px",
          left: 0,
          width: '100%',
          overflow: 'hidden',
          zIndex: 0,
        }}
      ></Box>
      {/* {descriptionString && (
        <>
          {descriptionString && (
            <React.Fragment key="description">
              {descriptionString.toString().startsWith('<') ? (
                <div
                  contentEditable="false"
                  dangerouslySetInnerHTML={{ __html: getDescription() || '' }}
                  style={{
                    fontSize: '2vh'
                  }}
                ></div>
              ) : (
                <Typography sx={{ color: 'text.secondary' }} variant="body2">
                  {getDescription()}
                </Typography>
              )}
            </React.Fragment>
          )} */}
      {/* <Typography variant="body2" sx={{ color: "text.secondary" }}>
          <div dangerouslySetInnerHTML={{ __html: getDescription() || "" }} style={{margin:'5px'}}></div>
        </Typography> */}
      {/* {Object.keys(guest).length !== 0 && (
            <Link
              underline="none"
              onClick={() => navigate('/hotel-information')}
              sx={{ ml: 0.5, fontSize: 'inherit', fontWeight: 600 }}
            >
              Read More..
            </Link>
          )}
        </>
      )} */}
    </CardPaper>
  );
};

export { InfoCard };
