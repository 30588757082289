import { Grid } from '@mui/material';

import useHotelService from './hotel-ser-hooks';
import { HeaderLayout, Layout } from '../InRoomDining';
import { FullScreenLoader, ServiceCard } from '../../components';
import { useParams, useSearchParams } from 'react-router-dom';
import useSubProperty from '../SubProperty/sub-property-hooks';
import { TalkToUS } from '../../components/TalkToUs';
import { customTheme } from '../../constants';
import { ThemeProvider } from '@material-ui/styles';
const Services = () => {
  const [searchParams] = useSearchParams();
  const SPID = searchParams.get('SPID');
  const { hotelService, loading } = useHotelService();
  const { subProperties, loading: spLoading } = useSubProperty();
  const { id } = useParams();
  if (loading || spLoading) {
    return <FullScreenLoader />;
  }
  // console.log("hotelService=>", hotelService)
  return (
    <Layout>
      <HeaderLayout title="Facilities" >
        <TalkToUS sx={{ alignItems: "center" }}></TalkToUS>
      </HeaderLayout>
      <ThemeProvider theme={customTheme}>
        <Grid container spacing={2}
          sx={{
            px: 2,
            py: 0,
            flex: 1,
            mt: "0px",
            overflow: 'auto',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&::-webkit-scrollbar': { display: 'none' },
            // ...BIG_TILE_RESPONSIVE
          }}
        >
          {SPID
            ? Array.isArray(subProperties) &&
            subProperties
              ?.find(sp => sp?.id === SPID)?.sub_services
              ?.filter(ss => hotelService?.find(hs => hs.id === id)?.sub_services?.some(ess => ss?.name === ess?.name))
              ?.map((hotelService: any) => {
                return (
                  <Grid  item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <ServiceCard
                      showInfo={true}
                      key={hotelService?.name}
                      label={hotelService?.name}
                      description={hotelService?.desc}
                      image={hotelService?.img as string}
                      serviceID={hotelService?.service_id}
                      booking={hotelService?.booking_url}
                      bookingForm={hotelService?.booking_form}
                      spaIntegration={hotelService?.spa_integration}
                    />
                  </Grid>
                );
              })
            : Array.isArray(hotelService) &&
            hotelService
              ?.find(service => service.id === id)
              ?.sub_services?.map((hotelService: any) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <ServiceCard
                      showInfo={true}
                      key={hotelService?.name}
                      label={hotelService?.name}
                      description={hotelService?.desc}
                      image={hotelService?.img as string}
                      serviceID={hotelService?.service_id}
                      booking={hotelService?.booking_url}
                      bookingForm={hotelService?.booking_form}
                      spaIntegration={hotelService?.spa_integration}
                    />
                  </Grid>
                );
              })}
        </Grid>
      </ThemeProvider>
    </Layout>
  );
};

export default Services;
