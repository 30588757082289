import {
  Box,
  Button,
  Dialog,
  // DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputBase,
  Radio,
  RadioGroup,
  // Rating,
  SelectChangeEvent,
  Stack,
  Typography
} from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
/* import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder'; */

import {
  // FeedbackIcon,
  FullScreenLoader
  // PromotionsIcon
} from '../../../components';
import {
  useFeedbackListQuery,
  useHotelDetailsLazyQuery,
  usePropertyDetailsQuery,
  useSubmitFeedbackMutation
} from '../../../generated/graphql';
import { CardPaper } from './CardPaper';
import { useAuth } from '../../../store';
import { useDomain, useSweetAlert } from '../../../utils';
import Swal from 'sweetalert2';
import { HeaderLayout, Layout } from '../../InRoomDining';
import { TalkToUS } from '../../../components/TalkToUs';
import { CloseOutlined } from '@mui/icons-material';
import { FeedbackReviewIcon } from '../../../components/Icons/FeedbackReviewIcon';
import { makeStyles } from '@mui/styles';
// import { split } from 'lodash';
interface Props {
  hideFeedback?: boolean;
  hidePromotionTile?: boolean;
}
const useStyles = makeStyles(({ theme }: any) => ({
  customeDialog: {
    width: '400px',
    maxWidth: '100%',
    height: '206px',
    borderRadius: '10px',
    overflowY: 'hidden',
    marginLeft: 0,
    marginRight: 0
  }
}));
const Feedback = ({ hideFeedback, hidePromotionTile }: Props) => {
  const classes = useStyles();
  const sweetAlert = useSweetAlert();
  const guest = useAuth((state: any) => state.guest);
  const navigate = useNavigate();
  // const [open, setOpen] = React.useState(false);
  const [comment, setComment] = React.useState('');
  // const [showFeedback, setShowFeedback] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const [feedBackType, setFeedBackType] = React.useState<any>('');
  const [feedback, setFeedback] = React.useState<any>('');
  /* const location = useLocation();
    const currentUrl = location.pathname;
    const roomId = split(currentUrl, "/")[1]; */
  // const [ratings, setRatings] = React.useState<number | null>(0);
  const { data: feedbackList, loading: loadingFeedbackList } =
    useFeedbackListQuery({
      variables: {
        propertyID: guest?.property_id
      }
    });
  const [createFeedback, { loading: loadingSubmitFeedback }] =
    useSubmitFeedbackMutation();

  const [hotelDetailsQuery, { loading: loadingMeta }] =
    useHotelDetailsLazyQuery();

  const domainId = useDomain();
  const { data: propertyDetails, loading: loadingProperty } =
    usePropertyDetailsQuery({
      variables: {
        domain: domainId as string
      }
    });

  React.useEffect(() => {
    const propertyID = propertyDetails?.getPropertyByDomain?.id;
    if (!propertyID) return;

    hotelDetailsQuery({
      variables: {
        propertyID
      }
    });
  }, [propertyDetails?.getPropertyByDomain?.id, hotelDetailsQuery]);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    // setOpen(false);
    // setRatings(0);
    setComment('');
    setFeedBackType('');
  };

  const showConfirmationDialog = async () => {
    return Swal.fire({
      title: 'Agent Request',
      text: 'Do you want to talk our agent for complaint?',
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#808080',
      cancelButtonColor: '#7066e0'
    });
  };

  const onSubmitFeedback = async () => {
    const foundType = feedbackList?.getFeedbackTypeList?.find(
      item => item?.id === parseInt(feedback)
    );
    if (foundType?.name === 'Complaint') {
      const result = await showConfirmationDialog();
      if (result.isConfirmed) {
        navigate(`/chat`, { state: { comment } });
        // setOpenDialog(true);
        handleClose();
      } else {
        try {
          const res = await createFeedback({
            variables: {
              input: {
                property_id: guest?.property_id,
                room_id: guest?.room_id,
                feedback_type_id: feedBackType?.id || 10,
                feedback: comment
              }
            }
          });
          if (res.data?.createFeedback?.code === '200') {
            /* sweetAlert.fire({
                            text: 'Complaint Submitted Successfully',
                            icon: 'success',
                            timer: 1000
                        }); */
            setShowDialog(true);
          } else {
            sweetAlert.fire({
              text: res.data?.createFeedback?.message,
              icon: 'error'
            });
          }
          handleClose();
        } catch (error) {
          sweetAlert.fire({
            text: 'Something went wrong',
            icon: 'error'
          });
        }
      }
    } else {
      try {
        const res = await createFeedback({
          variables: {
            input: {
              property_id: guest?.property_id,
              room_id: guest?.room_id,
              feedback_type_id: feedBackType?.id || 10,
              feedback: comment
            }
          }
        });
        if (res.data?.createFeedback?.code === '200') {
          /* sweetAlert.fire({
                        text: 'Feedback Submitted Successfully',
                        icon: 'success',
                        timer: 1000
                    }); */
          setShowDialog(true);
        } else {
          sweetAlert.fire({
            text: res.data?.createFeedback?.message,
            icon: 'error'
          });
        }
        handleClose();
      } catch (error) {
        sweetAlert.fire({
          text: 'Something went wrong',
          icon: 'error'
        });
      }
    }
  };

  React.useEffect(() => {
    if (!feedBackType && feedbackList) {
      setFeedBackType(
        feedbackList?.getFeedbackTypeList &&
          feedbackList?.getFeedbackTypeList[0]
      );
      setFeedback(
        feedbackList?.getFeedbackTypeList &&
          feedbackList?.getFeedbackTypeList[0]?.id
      );
    }
  }, [feedBackType, feedbackList?.getFeedbackTypeList, feedbackList]);

  if (
    loadingFeedbackList ||
    loadingSubmitFeedback ||
    loadingProperty ||
    loadingMeta
  ) {
    return <FullScreenLoader />;
  }
  const handleChange = (event: SelectChangeEvent) => {
    setFeedback(event.target.value);
  };

  return (
    <Layout index={5}>
      <HeaderLayout title="Feedback">
        <TalkToUS sx={{ alignItems: 'center' }}></TalkToUS>
      </HeaderLayout>
      <Box
        sx={{
          mt: 3,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          px: 2
        }}
      >
        {
          <CardPaper
            sx={{
              // height: "370px",
              px: 2,
              py: 2,
              borderRadius: '10px',
              boxShadow: '1px 1px 10px 0px rgba(0, 0, 0, 0.25)',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column'
            }}
          >
            {/* {propertyMeta?.getMeta?.feedback_desc?.toString().startsWith('<') ? (
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "start" }}
                                contentEditable="false"
                                dangerouslySetInnerHTML={{
                                    __html: propertyMeta?.getMeta?.feedback_desc?.toString() || ''
                                }}
                            ></Box>
                        ) : (
                            <Typography sx={{ color: 'text.secondary' }}>
                                {propertyMeta?.getMeta?.feedback_desc}
                            </Typography>
                        )} */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                '@media(min-width:680px)': {
                  px: '5%'
                },
                mb: 2
              }}
            >
              <Typography
                sx={{
                  color: 'text.primary',
                  fontSize: '19px',
                  fontWeight: '700',
                  lineWeight: '22.8px'
                }}
              >
                Please share your experience with us.
              </Typography>
              {/* <Typography
                sx={{
                  color: 'text.secondary',
                  fontSize: '12px',
                  fontWeight: '700',
                  lineWeight: '14.4px'
                }}
              >
                Your Feedback is Valuable!
              </Typography>
              <Typography
                sx={{
                  color: 'text.secondary',
                  fontSize: '12px',
                  fontWeight: '700',
                  lineWeight: '14.4px'
                }}
              >
                Please share your experience with us.
              </Typography> */}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                overflow: 'hidden',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none'
              }}
            >
              <FormControl
                component="fieldset"
                sx={{
                  m: 0,
                  minWidth: '100%',
                  color: 'text.tile',
                  display: 'flex',
                  justifyContent: 'center'
                }}
                size="small"
              >
                {(feedbackList?.getFeedbackTypeList?.length || 1) > 1 && (
                  <RadioGroup
                    aria-label="feedback-type"
                    name="feedback_type_id"
                    value={feedback}
                    onChange={handleChange}
                  >
                    <Stack direction="row" spacing={1}>
                      {feedbackList?.getFeedbackTypeList?.map(
                        (feedback: any) => (
                          <FormControlLabel
                            key={feedback.id}
                            value={feedback.id.toString()} // Make sure to convert the value to a string
                            control={<Radio size="small" />}
                            label={feedback.name}
                          />
                        )
                      )}
                    </Stack>
                  </RadioGroup>
                )}
              </FormControl>

              {/* {(
                                <Rating
                                    size="large"
                                    value={ratings}
                                    onChange={(event, newValue) => {
                                        setRatings(newValue);
                                    }}
                                    icon={
                                        <StarIcon fontSize="inherit" sx={{ color: 'primary.main' }} />
                                    }
                                    emptyIcon={
                                        <StarBorderIcon
                                            fontSize="inherit"
                                            sx={{ color: 'primary.main' }}
                                        />
                                    }
                                    sx={{ mb: 2 }}
                                />
                            )} */}
              <InputBase
                rows={8}
                multiline
                value={comment}
                placeholder="Write your comment here"
                onChange={e => setComment(e.target.value)}
                sx={{
                  p: 1.5,
                  '@media(max-width:700px)': {
                    width: '100%'
                  },
                  '@media(min-width:700px)': {
                    width: '90%'
                  },
                  width: '366px',
                  borderRadius: '10px',
                  border: '0.2px solid rgba(69, 90, 100, 1)',
                  color: 'text.tile'
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 2
                }}
              >
                <Button
                  variant="contained"
                  onClick={onSubmitFeedback}
                  autoFocus
                  sx={{
                    flex: 1,
                    mt: 2,
                    backgroundColor: 'rgba(178, 162, 121, 1)',
                    color: 'text.primary',
                    fontWeight: '700',
                    fontSize: '15px',
                    lineHeight: '18px',
                    border: '1px solid rgba(114, 104, 79, 1)',
                    px: 1.5,
                    py: 2,
                    borderRadius: '4px',
                    width: '136px',
                    '&:hover': {
                      backgroundColor: 'rgba(150, 140, 100, 1)', // Change the background color on hover
                      borderColor: 'rgba(100, 90, 60, 1)'
                    }
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </CardPaper>
        }

        <Dialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          classes={{ paper: classes.customeDialog }}
        >
          <DialogTitle
            sx={{ display: 'flex', justifyContent: 'flex-end', px: 1, py: 1 }}
          >
            <IconButton onClick={() => setShowDialog(false)}>
              <CloseOutlined sx={{ fontSize: '24px' }}></CloseOutlined>
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'space-between',
              mt: -2,
              flex: '0.5 1 auto'
            }}
          >
            <FeedbackReviewIcon sx={{ fontSize: '100px' }}></FeedbackReviewIcon>
            <Typography
              sx={{
                fontSize: '22px',
                fontWeight: '700',
                lineHeight: '26.4px',
                py: 0.5
              }}
            >
              Thank you for your Feedback
            </Typography>
          </DialogContent>
        </Dialog>
      </Box>
    </Layout>
  );
};

export { Feedback };
