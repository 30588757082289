import { Box, TextField, InputAdornment, Typography, Grid, CircularProgress } from "@mui/material";
import { HeaderLayout, Layout } from "../InRoomDining";
import { SearchOutlined } from "@mui/icons-material";
import React, { useEffect } from "react";
import { TalkToUS } from "../../components/TalkToUs";
import { SpaDrawer } from "../../components/SpaDrawer";
import { SpaData } from "./type";
import { requestWithToken } from "../../api/spaApis";
import { useDomain } from "../../utils";
import { usePropertyDetailsQuery } from "../../generated/graphql";
import SpaCard from "../../components/SpaCard";
const Spa = () => {
    const [searchQuery, setSearchQuery] = React.useState("");
    const [activeType, setActiveType] = React.useState<number>();
    const [categories, setCategories] = React.useState<any[]>([]);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const domainId = useDomain();
    const { data: propertyDetails } = usePropertyDetailsQuery({
        variables: {
            domain: domainId as string
        }
    });

    const [treatmentList, setTreatmentList] = React.useState<any[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            if (propertyDetails) {
                try {
                    const options = {
                        spa_id: propertyDetails?.getPropertyByDomain?.spa_id,
                    };
                    const response = await requestWithToken("ga/fetch-treatment-list", options);
                    const data = await response.json(); // Assuming response is JSON
                    if (data?.success) {
                        setTreatmentList(data?.list);
                    } else {
                        setTreatmentList([]);
                        console.log("Error fetching data:", data?.message);
                    }
                    setLoading(true);
                } catch (error) {
                    console.log("Error fetching data:", error);
                    setTreatmentList([]);
                    setLoading(true);
                }
            }

        };
        fetchData();
    }, [propertyDetails]);
    useEffect(() => {
        const tempCategories: any = [];
        treatmentList.forEach((item: any) => {
            tempCategories.push(item?.category);
        })
        const uniqueCategories = Array.from(new Map(tempCategories.map((item: any) => [item?.id, item])).values());
        setCategories(uniqueCategories);
    }, [treatmentList])

    const [filteredTreatments, setFilteredTreatments] = React.useState<any[]>([]);
    const [currentTreatment, setCurrentTreatment] = React.useState<SpaData>({} as SpaData);
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    }
    const handleClick = (targetCatId: number) => {
        if (activeType === targetCatId) {
            setActiveType(undefined);
        } else {
            setActiveType(targetCatId);
        }
    }
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    React.useEffect(() => {
        if (activeType) {
            const temp = treatmentList.filter(treatment => treatment.category.id === activeType);
            setFilteredTreatments(temp);
        } else {
            setFilteredTreatments(treatmentList);
        }
    }, [activeType, treatmentList])

    React.useEffect(() => {
        if (searchQuery) {
            const temp = treatmentList.filter(treatment => treatment?.description.toLowerCase().includes(searchQuery.toLowerCase()));
            setFilteredTreatments(temp);
        } else {
            setFilteredTreatments(treatmentList);
        }
    }, [searchQuery, treatmentList])

    return (
        <Layout>
            <HeaderLayout title="Silk Spa">
                <TalkToUS></TalkToUS>
            </HeaderLayout>
            {open && <SpaDrawer
                open={open}
                handleClose={handleClose}
                handleOpen={handleOpen}
                data={currentTreatment}
            >
            </SpaDrawer>}
            {loading ?
                (<Box
                    sx={{
                        px: 2,
                        py: 2,
                        flex: 1,
                        overflow: "auto",
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                        // TODO:
                        // This color is only used once keeping it here for a while
                    }}
                >

                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: "19px",
                            color: "text.primary",
                        }}
                    >
                        Treatments
                    </Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Search Treatment"
                        value={searchQuery}
                        onChange={onChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchOutlined />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '40px', // Adjust height here
                            },
                            mt: 1,
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 1,
                            mt: 2,
                        }}
                    >
                        {categories && categories.length > 0 ?
                            categories.map((category) =>
                                <Box
                                    key={category?.id}
                                    sx={{
                                        borderRadius: "5px",
                                        px: 1.5,
                                        py: 0.5,
                                        height: "32px",
                                        display: "flex",
                                        backgroundColor: "#FFFFFF",
                                        boxShadow: "0px 1px 10px 0px rgba(0, 0, 0, 0.25)",
                                        border: activeType === category?.id ? "1.5px solid #B2A279" : "none",
                                    }}
                                    onClick={() => {
                                        handleClick(category?.id);
                                    }}
                                >
                                    <Typography sx={{
                                        fontWeight: 700,
                                        fontSize: "15px",
                                        color: "text.primary",
                                    }}>
                                        {category?.name}
                                    </Typography>
                                </Box>
                            )
                            : <></>
                        }
                    </Box>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        {filteredTreatments && filteredTreatments.length > 0 ?
                            filteredTreatments.map((treatment) =>
                                <Grid key={treatment?.id} item xs={12} sm={6} md={4} lg={3} xl={3}>
                                    <SpaCard treatment={treatment} handleOpen={handleOpen} setCurrentTreatment={setCurrentTreatment}></SpaCard>
                                </Grid>
                            ) :
                            <> </>
                        }
                    </Grid>
                </Box>
                )
                :
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <CircularProgress></CircularProgress>
                </Box>
            }
        </Layout>
    );
};
export default Spa;