import { createTheme } from '@mui/material/styles';
export const DRAWER_WIDTH = 240;

export const SYSTEM_FONTS = [
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];
export const APP_FONT = "'Lato', sans-serif;";

export const SWR_CONFIG = {
  // refreshInterval: 2000,
  shouldRetryOnError: false,
};

export const LOGO_FONT = {
  color: "white",
  fontWeight:700,
  fontSize: "19px",
  lineHeight: "22.8px"
}
export const SERVICE_ITEM_FONT = {
  fontWeight: "700",
  fontSize: "19px",
  lineHeight: "22.8px",
}
export const SWIPE_FONT = {
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "16.8px",
}
export const SERVICE_SUBITEM_FONT = {
  fontWeight: "700",
  fontSize: "17px",
  lineHeight: "20.4px",
}
export const MENU_ITEM_FONT = {
  color:"white",
  fontWeight: "700",
  fontSize: "22px",
  lineHeight: "26.4px",
}
export const DETAIL_TITLE_FONT = {
  fontWeight: "700",
  fontSize: "17px",
  lineHeight: "20.59px",
}
export const CHAT_FONT = {
  fontWeight: "700",
  fontSize: "10px",
  lineHeight: "12px",
}
export const DETAIL_CONTENT_FONT = {
  fontWeight: "700",
  fontSize: "15px",
  lineHeight: "18px",
}
export const SMALL_TILE_SIZE = {
  '@media (max-width: 159px)': {
    width: '100%', // or any style you want to apply when the width is less than 159px
  },
  width: "159px",//theme => theme.spacing(10),
  height: "126px",//theme => theme.spacing(10)
}
export const BIG_TILE_SIZE = {
  // width: "398px",//theme => theme.spacing(10),
  height: "100%",//theme => theme.spacing(10)
  borderRadius: "20px",
}

export const SMALL_TILE_RESPONSIVE = {
  
}
export const BIG_TILE_RESPONSIVE = {

}

export const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 300,
      md: 700,
      lg: 1200,
      xl: 1600,
    },
  },
});

