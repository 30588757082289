import {
  Box,
  Theme,
  SxProps,
  Typography,
  CircularProgress,
  Tooltip
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { CardPaper } from './CardPaper';
import { SERVICE_SUBITEM_FONT, SERVICE_ITEM_FONT, SMALL_TILE_SIZE, SWIPE_FONT } from '../../../constants';
import { ArrowForwardIosSharp } from '@mui/icons-material';
import useWindowDimensions from '../../../constants/GetWindowsDimessions';
interface SliderProp {
  title: string;
  loading?: boolean;
  count?: number,
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
  onTitleClick?: () => void;
}

const Slider = ({ title, children, sx, loading, count, onTitleClick }: SliderProp) => {
  const handleWheel = (event: React.WheelEvent) => {
    const container = event.currentTarget;
    const isTileList = event.target instanceof HTMLElement && event.target.classList.contains('TileList');
    if (event.deltaY !== 0 && !isTileList) {
      container.scrollLeft += event.deltaY;
    }
  };
  const { screenWidth } = useWindowDimensions();
  const contentWidth = count && ((159 * count) + 12 * (count - 1));
  const temp = contentWidth && screenWidth && (screenWidth > contentWidth) ? false : true;
  return (
    <Box className="Slider" sx={{ mt: 3, ...(sx ? sx : { px: { xs: 2, sm: 2, md: 4, lg: 6 } }) }}>
      <Box sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}>
        <Typography
          variant="h6"
          sx={{ ...SERVICE_ITEM_FONT }}
          onClick={() => onTitleClick && onTitleClick()}
        >
          {title}
        </Typography>
        {temp && <Box sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
          <Typography sx={{ ...SWIPE_FONT }}>
            Swipe
          </Typography>
          <ArrowForwardIosSharp sx={{ width: "14px", height: "16px", mt: "1px", ml: "-2px", color: "text.primary" }}></ArrowForwardIosSharp>
        </Box>}

      </Box>


      {!loading ? (
        <Box className="TileList"
          sx={{
            overflowX: 'scroll',
            overflowY: 'hidden',
            whiteSpace: 'nowrap',
            scrollbarWidth: 'none',
            paddingBottom: 1.5 // For Firefox
          }}
          onWheel={handleWheel}
        >
          {children}
        </Box>
      ) : (
        <Box sx={{ my: 4, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

interface SliderCardProp {
  index: number;
  title?: any;
  link?: string;
  icon: React.ReactNode;
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
  gettitle?: string;
  externalLink?: string | null;
  onClick?: () => void;
}

const SliderCard = ({
  index,
  title,
  link,
  icon,
  sx,
  gettitle,
  externalLink,
  onClick
}: SliderCardProp) => {
  const imgUrl = typeof icon === 'string' ? (icon) : (
    `${icon}`
  )

  const backgroundImage = {
    background: 'url(' + imgUrl + ')',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  }

  return (
    <Box className="Tiles"
      sx={{
        ...SMALL_TILE_SIZE,
        mt: 0.5,
        borderRadius: "10px",
        display: 'inline-block',
        boxShadow: '25px 30px 40px -30px rgba(0, 0, 0, 0.3)',
        mr: index !== 0 ? 2 : 1.5,
        ...(sx ? sx : {})
      }}
    >
      {externalLink && (
        <a href={externalLink} target="_blank" rel="noopener noreferrer">
          <CardPaper className='ExternLink TileContent'
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: 2,
              py: 2,
              ...SMALL_TILE_SIZE,
              ...backgroundImage,
            }}
          >
            {typeof icon === 'string' ? (
              <div></div>
            ) : (
              icon
            )}
          </CardPaper>
        </a>
      )}

      {link && (
        <RouterLink to={link || ''}>
          <CardPaper className='Link TileContent'
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: 2,
              py: 2,
              ...SMALL_TILE_SIZE,
              ...backgroundImage,
            }}
          >
            {typeof icon === 'string' ? (
              <div></div>
            ) : (
              icon
            )}
          </CardPaper>
        </RouterLink>
      )}

      {onClick && (
        <CardPaper className='OnClick TileContent'
          sx={{
            px: 3,
            py: 2.5,
            ...SMALL_TILE_SIZE,
            ...backgroundImage,
          }}
          onClick={onClick}
        >
          {typeof icon === 'string' ? (
            <div></div>
          ) : (
            icon
          )}
        </CardPaper>
      )}

      {title && (<Typography className='title1'
        variant="body2"
        textAlign="center"
        sx={{ mt: 1, color: 'text.primary' }}
      >
        {title}
      </Typography>)}
      {gettitle && (
        <Tooltip enterTouchDelay={0} title={gettitle}>
          <Typography className='title2'
            variant="body2"
            textAlign="left"
            sx={{ mt: 1, color: 'text.primary', ...SERVICE_SUBITEM_FONT }}
          >
            {gettitle.length > 20 ? gettitle?.slice(0, 20) + '...' : gettitle}
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
};

export { Slider, SliderCard };
