import { TalkToUS } from '../../components/TalkToUs';
import { Layout } from '../InRoomDining';
import { useNavigate, useParams } from 'react-router-dom';
import { SpaData } from '../Spa/type';
import React from 'react';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuth } from '../../store';
import { requestWithToken } from '../../api/spaApis';
import { useDomain, useSweetAlert } from '../../utils';
import { usePropertyDetailsQuery } from '../../generated/graphql';
const SelectSlot = () => {
    const navigate = useNavigate();
    const sweetAlert = useSweetAlert();
    const id: any = useParams().id;
    const [therapistValue, setTherapistValue] = React.useState('Male');
    const [curDuration, setCurDuration] = React.useState<any>(null);
    const [curDate, setCurDate] = React.useState<any>(null);
    const [curTime, setCurTime] = React.useState<any>(null);
    const [availableSlots, setAvailableSlots] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState(true);
    const guest = useAuth((state: any) => state.guest);
    const [treatment, setTreatment] = React.useState<SpaData>({} as SpaData);
    const [timeSlotsLoading, setTimeSlotsLoading] = React.useState(true);
    const [message, setMessage] = React.useState<string>('');
    const [booking, setBooking] = React.useState(false);
    const domainId = useDomain();
    const { data: propertyDetails } = usePropertyDetailsQuery({
        variables: {
            domain: domainId as string
        }
    });
    React.useEffect(() => {
        const fetchTreatment = async () => {
            if (id && propertyDetails) {
                try {
                    const options = {
                        spa_id: propertyDetails?.getPropertyByDomain?.spa_id
                    };
                    const response = await requestWithToken(
                        'ga/fetch-treatment/' + id,
                        options
                    );

                    const data = await response.json();
                    if (data?.success) {
                        setTreatment(data.data);
                        setCurDuration(data.data?.durationList[0]);
                    } else {
                        setTreatment({} as SpaData);
                    }
                    setLoading(false);
                } catch (error) {
                    setTreatment({} as SpaData);
                    setLoading(false);
                    console.log('Error fetching data:', error);
                }
            }
        };
        fetchTreatment();
    }, [id, propertyDetails]);
    
    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setTherapistValue(event.target.value);
    };
    const getAvailableTimeSlots = React.useCallback(async (date: any) => {
        try {
            setCurDate(date);
            const now = new Date();
            const bookignDateString = `${date?.day} ${date?.month
                } ${now.getFullYear()}`;
            const localDate = new Date(bookignDateString);
            const utcDate = new Date(
                localDate.getTime() - localDate.getTimezoneOffset() * 60000
            );
            const selectedDate = utcDate.toISOString();
            const options = {
                method: 'POST',
                body: {
                    date: selectedDate,
                    treatmentId: treatment?.id,
                    gender: therapistValue
                },
                spa_id: propertyDetails?.getPropertyByDomain?.spa_id
            };
            setTimeSlotsLoading(true);
            setCurTime(null);
            const response = await requestWithToken(
                'ga/fetch-slot-duration',
                options
            );
            const data = await response.json();
            const timeSlots = getTimeSlots(data?.list);
            setAvailableSlots(timeSlots);
            setTimeSlotsLoading(false);
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    }, [treatment, therapistValue, propertyDetails]);
    function getDateComponentsForRange(arrivalDate: any, departureDate: any) {
        const dates = [];
        const today = new Date();
        const arrival = new Date(arrivalDate);
        const departure = new Date(departureDate);

        let startDate;
        let endDate;

        if (arrival < today) {
            // If arrival date is in the future, use today as start date
            startDate = today;
            endDate = departure;
        } else {
            // Otherwise, use arrival date as start date
            startDate = arrival;
            endDate = departure;
        }

        // Ensure the endDate is at least 14 days after startDate
        const maxDate = new Date(startDate);
        maxDate.setDate(startDate.getDate() + 30); // 13 days from startDate

        if (endDate < maxDate) {
            maxDate.setDate(endDate.getDate() - 1);
        }

        for (
            let date = new Date(startDate);
            date <= maxDate;
            date.setDate(date.getDate() + 1)
        ) {
            const day = date.getDate();
            const month = date.toLocaleString('en-GB', { month: 'short' });
            const weekday = date.toLocaleString('en-GB', { weekday: 'short' });
            dates.push({ id: dates.length, day, month, weekday });
        }
        return dates;
    }
    function getTimeSlots(slotList: any[]) {
        const times: any[] = [];

        for (let i = 0; i < slotList.length; i++) {
            const slot = slotList[i];
            let start = new Date(slot?.startTime);
            const end = new Date(slot?.endTime);
            const startHours = start.getHours();
            const startMinutes = start.getMinutes();
            start.setHours(startHours);
            start.setMinutes(startMinutes);
            start.setSeconds(0);

            const endHours = end.getHours();
            const endMinutes = end.getMinutes();
            end.setHours(endHours);
            end.setMinutes(endMinutes);
            end.setSeconds(0);

            let j = 0;
            const currentTime = new Date();

            if (start < currentTime) {
                start = new Date(currentTime);
                const minutes = start.getMinutes();
                start.setMinutes(minutes + (30 - (minutes % 30)));
                start.setSeconds(0);
                start.setMilliseconds(0);
            }
            while (start <= end) {
                const hour = start.getHours();
                const minute = start.getMinutes().toString().padStart(2, '0');
                const period = hour < 12 ? 'am' : 'pm';
                const formattedHour = hour % 12 === 0 ? 12 : hour % 12;

                const timeString =
                    formattedHour < 10
                        ? `0${formattedHour}:${minute} ${period}`
                        : `${formattedHour}:${minute} ${period}`;

                times.push({ id: j++, time: timeString });

                start.setMinutes(start.getMinutes() + 30);
            }
        }

        return times;
    }
    React.useEffect(() => {
        if (treatment) {
            const date = getDateComponentsForRange(
                guest?.guest_arrival,
                guest?.guest_departure
            )[0];
            getAvailableTimeSlots(date);
        }

    }, [therapistValue, treatment,  guest?.guest_arrival, guest?.guest_departure, getAvailableTimeSlots]);
    const handleBooking = async () => {
        if (curDate === null || curTime === null) {
            sweetAlert.fire({
                icon: 'warning',
                text: 'Please select date and time'
            });
            return;
        }
        if (availableSlots?.length === 0) {
            sweetAlert.fire({
                icon: 'warning',
                text: 'Unfortunately, no slots available'
            });
            return;
        }
        if (curDuration === null) {
            sweetAlert.fire({
                icon: 'warning',
                text: 'Please select duration'
            });
            return;
        }
        if (therapistValue === null) {
            sweetAlert.fire({
                icon: 'warning',
                text: 'Please select therapist'
            });
            return;
        }
        const now = new Date();
        const bookignDateString = `${curDate?.day} ${curDate?.month
            } ${now.getFullYear()} ${curTime?.time}`;
        const localDate = new Date(bookignDateString);
        const utcDate = new Date(localDate.getTime());
        const bookingDate = utcDate.toISOString();

        const options = {
            method: 'POST',
            body: {
                guestData: {
                    profileId: guest?.guest_id,
                    name: guest?.guest_name,
                    arrival: guest?.guest_arrival,
                    departure: guest?.guest_departure,
                    roomNumber: guest?.room,
                    type: 'INHOUSE'
                },
                remarks: message,
                bookingDateTime: bookingDate,
                treatmentDurationId: curDuration?.id,
                staffGender: therapistValue
            },
            spa_id: propertyDetails?.getPropertyByDomain?.spa_id
        };
        try {
            setBooking(true);
            const response = await requestWithToken('ga/upsert-appointment', options);
            const data = await response.json();
            if (data?.success) {
                sweetAlert.fire({
                    icon: 'success',
                    text: 'Your booking has been successfully created. Thank you for choosing Hotlync Spa.'
                });
                navigate(-1);
            } else {
                sweetAlert.fire({
                    icon: 'error',
                    text: data?.message
                });
            }
            setBooking(false);
        } catch (error) {
            setBooking(false);
            sweetAlert.fire({
                icon: 'error',
                text: 'Something went wrong. Please try again later.'
            });
            console.log('Error fetching data:', error);
        }
    };
    // Example usage:
    const backgroundStyles = {
        background: `linear-gradient(rgba(0, 0, 0,  0.3), rgba(0, 0, 0, 0)), url(${treatment?.imageList?.length > 0
            ? treatment?.imageList[0].path
            : '/assets/no.jpg'
            })`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '0px 0px 30px 30px'
    };
    return (
        <Layout>
            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh'
                    }}
                >
                    <CircularProgress color="primary" />
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                        '&::-webkit-scrollbar': { display: 'none' }
                    }}
                >
                    <Box
                        sx={{
                            px: 2,
                            py: 1,
                            height: '82px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            '@media(max-width:280px)': {
                                flexDirection: 'column'
                            },
                            boxShadow:
                                '0px 8px 10px 0px rgba(0, 0, 0, 0.25), 0px 4px 8px rgba(0, 0, 0, 0.06)',
                            ...backgroundStyles
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton disableRipple onClick={() => navigate(-1)}>
                                <ArrowBackIcon sx={{ color: 'white' }} />
                            </IconButton>
                            <Typography
                                sx={{
                                    cursor: 'pointer',
                                    fontWeight: 700,
                                    fontSize: '22px',
                                    color: 'rgba(255, 255, 255, 1)',
                                    lineHeight: '26.4px'
                                }}
                                onClick={() => navigate(-1)}
                            >
                                Select Slot
                            </Typography>
                        </Box>
                        <TalkToUS sx={{ alignItems: 'center' }}></TalkToUS>
                    </Box>
                    <Box
                        className="select-slot"
                        sx={{
                            mt: 1,
                            px: 2,
                            py: 1,
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column'
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: '19px',
                                color: 'rgba(0,0,0,1)',
                                lineHeight: '22.8px'
                            }}
                        >
                            {treatment?.name}
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 700,
                                fontSize: '11px',
                                color: 'rgba(51, 51, 51, 1)',
                                lineHeight: '16.95px'
                            }}
                        >
                            {treatment?.description}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            px: 2,
                            py: 1
                        }}
                    >
                        <Typography>Duration</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                flexDirection: 'row',
                                gap: 1,
                                overflowX: 'auto',
                                overflowY: 'hidden',
                                scrollbarWidth: 'none',
                                py: 1
                            }}
                        >
                            {treatment?.durationList?.map((duration: any) => (
                                <Box
                                    key={duration?.id}
                                    sx={{
                                        borderRadius: '5px',
                                        px: 1.5,
                                        py: 0.8,
                                        height: '50px',
                                        minWidth: '80px',
                                        display: 'flex',
                                        backgroundColor: '#FFFFFF',
                                        flexDirection: 'column',
                                        boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.25)',
                                        justifyContent: 'space-between',
                                        border:
                                            curDuration?.id === duration?.id
                                                ? '1.5px solid #B2A279'
                                                : 'none'
                                    }}
                                    onClick={() => setCurDuration(duration)}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: '14px',
                                            lineHeight: '16.8px'
                                        }}
                                    >
                                        {duration?.duration} mins
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: '14px',
                                            lineHeight: '16.8px'
                                        }}
                                    >
                                        AED {duration?.price}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box
                        className="Therapist-Sex"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            px: 2,
                            pb: 2
                        }}
                    >
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">
                                Therapist Preference
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                value={therapistValue}
                                onChange={handleChange}
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        fontSize: '20px',
                                        color: 'rgba(178, 162, 121, 1)'
                                    }
                                }}
                            >
                                <FormControlLabel
                                    value="Male"
                                    control={<Radio />}
                                    label="Male"
                                />
                                <FormControlLabel
                                    value="Female"
                                    control={<Radio />}
                                    label="Female"
                                />
                                <FormControlLabel value="" control={<Radio />} label="Any" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box
                        className="select-date"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            px: 2
                        }}
                    >
                        <Typography>Select Date</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                flexDirection: 'row',
                                py: 1,
                                overflowX: 'auto',
                                overflowY: 'none',
                                scrollbarWidth: 'none',
                                gap: 1
                            }}
                        >
                            {getDateComponentsForRange(
                                guest?.guest_arrival,
                                guest?.guest_departure
                            ).map((date: any) => (
                                <Box
                                    key={date?.id}
                                    sx={{
                                        borderRadius: '5px',
                                        px: 1.5,
                                        py: 0.8,
                                        height: '77px',
                                        width: '72px',
                                        minWidth: '72px',
                                        display: 'flex',
                                        backgroundColor: '#FFFFFF',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.25)',
                                        justifyContent: 'space-between',
                                        border:
                                            curDate?.id === date?.id ? '1.5px solid #B2A279' : 'none'
                                    }}
                                    onClick={() => getAvailableTimeSlots(date)}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: '22px',
                                            lineHeight: '26.4px'
                                        }}
                                    >
                                        {date?.day}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: '15px',
                                            lineHeight: '18px'
                                        }}
                                    >
                                        {date?.month}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: '13px',
                                            lineHeight: '15.6px',
                                            color: 'rgba(106, 106, 106, 1)'
                                        }}
                                    >
                                        {date?.weekday}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box
                        className="select-time"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            px: 2,
                            py: 2
                        }}
                    >
                        <Typography>Select Time</Typography>
                        {timeSlotsLoading ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%'
                                }}
                            ></Box>
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    flexDirection: 'row',
                                    py: 1,
                                    overflowX: 'auto',
                                    overflowY: 'none',
                                    scrollbarWidth: 'none',
                                    gap: 1
                                }}
                            >
                                {availableSlots.map((time: any) => (
                                    <Box
                                        key={time?.id}
                                        sx={{
                                            borderRadius: '5px',
                                            px: 1.5,
                                            py: 0.5,
                                            height: '40px',
                                            minWidth: '110px',
                                            backgroundColor: '#FFFFFF',
                                            alignItems: 'center',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.25)',
                                            border:
                                                curTime?.id === time?.id
                                                    ? '1.5px solid #B2A279'
                                                    : 'none'
                                        }}
                                        onClick={() => setCurTime(time)}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: 700,
                                                fontSize: '17px',
                                                lineHeight: '22.8px'
                                            }}
                                        >
                                            {time.time}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </Box>
                    <Box
                        className="select-message"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.25)',
                            borderRadius: '5px',
                            m: 2
                        }}
                    >
                        <TextField
                            label="Note"
                            multiline
                            rows={4}
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                        />
                    </Box>
                    <Box
                        sx={{
                            borderRadius: '2px',
                            px: 3,
                            py: 1,
                            height: '40px',
                            backgroundColor: '#FFFFFF',
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            boxShadow: '0px 14px 56px 0px rgba(0, 0, 0, 0.25)'
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 700,
                                fontSize: '15px',
                                lineHeight: '18px'
                            }}
                        >
                            Total Payable
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 700,
                                fontSize: '15px',
                                lineHeight: '18px'
                            }}
                        >
                            AED {curDuration?.price || 0}
                        </Typography>
                    </Box>
                    <Box sx={{ px: 2 }}>
                        {!booking ? (
                            <Button
                                sx={{
                                    width: '100%',
                                    height: '40px',
                                    borderRadius: '4px',
                                    backgroundColor: 'rgba(178, 162, 121, 1)',
                                    border: '0.4px, solid, rgba(114, 104, 79, 1)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(178, 162, 121, 1)'
                                    },
                                    color: '#FFFFFF'
                                }}
                                onClick={handleBooking}
                            >
                                Book
                            </Button>
                        ) : (
                            <Button
                                sx={{
                                    width: '100%',
                                    height: '40px',
                                    borderRadius: '4px',
                                    backgroundColor: 'rgba(178, 162, 121, 1)',
                                    border: '0.4px, solid, rgba(114, 104, 79, 1)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(178, 162, 121, 1)'
                                    },
                                    color: '#FFFFFF'
                                }}
                            >
                                <CircularProgress />
                            </Button>
                        )}
                    </Box>
                </Box>
            )}
        </Layout>
    );
};

export default SelectSlot;
