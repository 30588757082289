import * as React from 'react';
import { format } from 'date-fns';
import { Box, Typography } from '@mui/material';

import {
  useGetGuestPromotionsLazyQuery,
  usePropertyDetailsQuery
} from '../../generated/graphql';
import { FullScreenLoader } from '../../components';
import { useDomain } from '../../utils';
import { HeaderLayout, Layout } from '../InRoomDining';
import { EmptyImage } from '../../components/Icons/EmptyImage';
import PromotionCard from './promotionCard';
import { TalkToUS } from '../../components/TalkToUs';

function Promotions() {
  const [promotionsQuery, { data: promotions, loading: loadingPromotions }] =
    useGetGuestPromotionsLazyQuery();

  const domainId = useDomain();
  const { data: propertyDetails, loading: loadingProperty } =
    usePropertyDetailsQuery({
      variables: {
        domain: domainId as string
      }
    });
  React.useEffect(() => {
    const propertyID = propertyDetails?.getPropertyByDomain?.id;
    if (!propertyID) return;

    promotionsQuery({
      variables: {
        propertyID,
        currDate: format(new Date(), 'yyyy-MM-dd')
      }
    });
  }, [propertyDetails?.getPropertyByDomain?.id, promotionsQuery]);

  if (loadingPromotions || loadingProperty) return <FullScreenLoader />;

  return (
    <Layout index={4}>
      <HeaderLayout title="Promotions" >
        <TalkToUS sx={{ alignItems: "center" }}></TalkToUS>
      </HeaderLayout>
      {!Boolean(promotions?.getGuestPromotions?.length) && (
        <Box
          sx={{
            p: 2,
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <EmptyImage style={{ height: '50%', width: '50%' }} />
            <Typography variant="h6" sx={{ mt: 2 }}>
              No Promotions Available
            </Typography>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          p: 2,
          overflow: 'auto',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        {promotions?.getGuestPromotions?.map(pro => {
          return <PromotionCard item={pro} />;
        })}
      </Box>
    </Layout>
  );
}

export { Promotions };
