import { Badge } from "@mui/material";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../store";
import { useTaskHistoryContext } from "../../../context/TaskHistoryContext";
import { useMyOrdersQuery } from "../../../generated/graphql";
import { split } from "lodash";
import { NavHomeIcon } from "../../../components/Icons/NavHomeIcon";
import { NavBasketIcon } from "../../../components/Icons/NavBasketIcon";
import { NavRoomServiceIcon } from "../../../components/Icons/NavRoomServiceIcon";
import { NavFeedbackIcon } from "../../../components/Icons/NavFeedbackIcon";
import { NavPromotionIcon } from "../../../components/Icons/NavPromotionIcon";

interface NavBarProps {
    index?: number,
}
const iconComponents = {
    OtherHousesOutlined: NavHomeIcon,
    ShoppingBagOutlined: NavBasketIcon,
    RoomServiceOutlined: NavRoomServiceIcon,
    StarBorderRounded: NavFeedbackIcon,
    DiscountOutlined: NavPromotionIcon
  };
const NavBar = ({ index }: NavBarProps) => {
    var indexValue = index ? index : 0;
    const location = useLocation();
    const navigate = useNavigate();
    const currentUrl = location.pathname;
    const roomId = split(currentUrl, "/")[1];
    const NavBarListRoute = useMemo(()=> ["/", "/order-history", "/service-history", "/promotions", "/feedback"], []);

    const guest = useAuth((state: any) => state.guest);

    const { taskHistoryData,  } = useTaskHistoryContext();//loadingTaskHistory

    const { data: orders, } = useMyOrdersQuery({//loading: loadingOrders 
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        variables: {
            guestID: guest?.guest_id,
            propertyID: guest?.property_id
        },
        skip: !guest?.guest_id
    });
    const orderHistory = orders?.getGuestOrders;
    const taskHistory = taskHistoryData?.getTaskHistory;
    /* if (loadingOrders || loadingTaskHistory) {
        return (
          <Box sx={{ my: 4, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        );
      } */
    const activeStyle = {
        color: 'black',
        background: "rgba(255, 239, 198, 1)",
        borderRadius: "10px",
        
    }
    const navItems = [
        {
            label: "Home",
            icon: "OtherHousesOutlined",
        },
        {
            label: "Basket",
            icon: "ShoppingBagOutlined",
            badge: orderHistory?.length,
        },
        {
            label: "Request",
            icon: "RoomServiceOutlined",
            badge: taskHistory?.length,
        },
        {
            label: "Promotion",
            icon: "DiscountOutlined",
        },
        {
            label: "Feedback",
            icon: "StarBorderRounded",
        },
    ]
    return (
        <BottomNavigation
            showLabels
            value={indexValue}
            onChange={(event, newValue) => {
                navigate(`/${roomId}${NavBarListRoute[newValue]}`)
            }}
            sx={{
                height: "72px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                px: 2,
                paddingBottom: 1,
                paddingTop: 0.5,
                background: "#B2A279",
                boxShadow: "0px 14px 56px 0px rgba(0, 0, 0, 0.14)",
                borderRadius: "10px 10px 0px 0px",
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1000, // Ensures it stays above other content
            }}
        >
            {navItems.map((item, index) => {
                const IconComponent = iconComponents[item?.icon as keyof typeof iconComponents];
                return (
                    <BottomNavigationAction label={item?.label}
                        key={index}
                        icon={
                            <Badge badgeContent={item?.badge} overlap="circular" sx={{ '& .MuiBadge-badge': { backgroundColor: 'rgba(0, 133, 255, 1)', color: "white", mt: 2 } }}>
                                <IconComponent sx={{fontSize: "24px !important", fontWeight: "1000", mt: 2}} />
                            </Badge>
                        }
                        sx={{
                            color: indexValue === index ? 'black' : 'white',
                            fontSize:"10px",
                            minWidth: "62px",
                            maxWidth: "62px",
                            minHeight: "62px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            gap: 0.1,
                            '&.Mui-selected': {
                                ...activeStyle,
                            },
                            
                        }}
                    />
                );
            })}
        </BottomNavigation>
    );
}
export default NavBar;

